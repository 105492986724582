footer {
    background-color: var(--white);
    color: var(--black);
    padding: 20px 0;
    padding-left: 12%;
    
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .col-12 {
    flex: 0 0 100%;
  }
  
  .col-sm-4 {
    flex: 0 0 calc(33.33% - 20px);
    margin-bottom: 20px;
  }
  
  .container h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: var(--top_bar_color);
    opacity: 80%;
  }
  
  p{
    margin: 0;
    padding: 0;
    list-style: none;
    color: var(--primary);
  }
  
  .social-icons {
    display: flex;
    align-items: center;
  }
  
  .social-icons a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--white);
    transition: all 0.3s ease-in-out;
    opacity: 80%;
  }
  
  .social-icons a:hover {
    transform: scale(1.2);
  }
  
  .social-icons a svg {
    width: 20px;
    height: 20px;
  }
  