.cart-item{
    display: flex;
    padding-top: 5%;
    padding-bottom: 2%;
    justify-content: space-between;
}

.cart-item .left{
    flex: 2;
}

.cart-item .right{
    flex: 1.5;
    display: flex;
    justify-content: space-between;
}

.cart-item-data{
    display: flex;
}

.cart-item-data .cart-item-desc{
    margin-left: 8%;
}

.cart-item-data img{
    width: 50px;
    height: 50px;
}

.cart-item-desc p{
    padding-top: 0;
    padding-bottom: 2%;
}

.cart-item-desc .extra{
    font-size: 10px;
}

.qty-div{
    border-radius: 5px;
    border: 2px solid var(--dark-red);
    height: fit-content;
}

.qty-div .qty-mngr{
    border: 0;
    background-color: var(--dark-red);
    width: 2.5vw;
    color: var(--white);
    cursor: pointer;
}

.p-qty{
    padding: 0 5px;
}

