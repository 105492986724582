.about-us {
    background-color: white;
    color: black;
    padding: 0 12%;
    text-align: justify;
  }
  
  .red-heading {
    color: red;
  }
  
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */  
    padding-top: 25px;
    width: 300%; /* enlarge beyond browser width */
    left: -100%; /* center */
    margin-bottom: 2%;
  }

  .video-container-wrapper {
    overflow: hidden;
    max-width: 100%;
 }
  
  .video-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
  