.checkout-main {
    padding: 0 12%;
    color: var(--top_bar_color);
    padding-top: 2%;
}

.pno::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

.form-heading{
    color: var(--dark-red);
    text-align: center;
    margin: 1% 0;
}

.shipping-form, .billing-form{
    padding: 2%;
}

.checkout{
    display: flex;
    justify-content: space-between;
}

.checkout .left{
    flex: 2;
    transition: all 1s ease;
}

.checkout .right{
    flex: 1;
}

.checkout .left .form{
    padding: 2% 0;
    display: flex;
    flex-direction: column;
}

.checkout .left .form input{
    padding: 1.5% 2%;
    margin: .5% 0;
    border-radius: 5px;
    border: 1px solid var(--primary);
    color: var(--primary);
    box-shadow: inset 0 0 1rem .1rem var(--light);
}

.checkbox{
    cursor: pointer;
    box-shadow: inset 0 0 0 0 var(--light) !important;
}

.checkout .right{
    padding: 2% 4%;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: inset 0 0 1rem 0 var(--light);
    margin-bottom: 1%;
}

.right h3{
    text-align: center;
    font-size: var(--font-md);
    color: var(--dark-red);
    padding: 1% 0;
}
.checkout .right .summary-data{
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weightMax);
}

.summary-data p{
    padding: 2% 0;
}

.summary-data .row-heading{
    color: var(--top_bar_color);
}

.summary-data .g-total{
    font-size: var(--font-md);
}

.order-btn{
    width: 100%;
    padding: .5rem;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    border-radius: 2px;
    color: var(--white);
    cursor: pointer;
    font-weight: var(--font-weightMid);
    margin: 10% 0;
  }
  
.order-btn:hover{
    box-shadow: 0 0 .8rem 0 var(--primary);
    transition: .2s box-shadow;
  }

  .otp h3{
    text-align: center;
    margin: 2% 0;
  }

  .otp input{
    padding: .2rem;
  }

  .otp button{
    padding: .2rem;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    border-radius: 2px;
    color: var(--white);
    cursor: pointer;
    margin: 0 2%;
  }

