.product {
    width: calc(100vw - 25vw);
    float: left;
    padding: 0 .2rem;
    margin-bottom: 2rem;
  }
  
  .wrapper {
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: var(--white);
    position: relative;
    transform: translateY(0);
  }
  
  .wrapper:hover{
    box-shadow: 0 0.2rem 1.5rem rgba(0, 0, 0, .5);
    transition: .3s box-shadow ease, .3s transform ease;
  }
  
  .image-div {
    position: relative;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: var(--light);
  }
  
  .background-image img{
    overflow: hidden;
    width: 100%;
    height: 15rem;
  }
  
  .product-price {
    background-color: var(--light);
    font-size: var(--font-md);
    font-weight: 600;
    position: absolute;
    top: 13rem;
    left: 1rem;
    padding: 0.5rem 1.2rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    z-index: 1;
  }

  .shop-icon-div{
    position: absolute;
    bottom: 0.3rem;
    right: 1rem;
  }
  
  .shop-icon{
    color: var(--dark-red);
    cursor: pointer;
  }

  .shop-icon:hover{
    background-color: var(--dark-red);
    padding: 10%;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    transition: all .2s ease;
  }

  .item-qty-div{
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    border-radius: 5px;
    border: 2px solid var(--dark-red);
    height: fit-content;
}

.item-qty-div .item-qty-mngr{
    border: 0;
    background-color: var(--dark-red);
    width: 2.5vw;
    color: var(--white);
    cursor: pointer;
}

.item-p-qty{
    padding: 0 5px;
}
  
  .product-meta{
    display: flex;
    justify-content: space-between;
  }
  .product-meta {
    background-color: var(--light);
    padding: 1.8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    font-size: 1.2rem;
    margin-top: -0.4rem;
    position: relative;
  }
  .product-meta figure{
    opacity: .6;
    display: inline-block;
  }

  #product-name{
    width: 50%;
  }
  
.product-meta .product-name{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .product-description {
    padding: 1.8rem;
    padding-top: 1rem;
  }
  
  .product-description p {
    font-size: var(--font-sm);
    height: 6rem;
    overflow: wrap;
    line-height: 1.4rem;
    color: var(--primary);
    font-family: var(--font-family-monospace);
  }
  
  .detail {
    color: var(--dark-red);
    padding: 1.8rem 0 0 0;
    margin-left: 1.8rem;
    position: absolute;
    top: 24rem;
    cursor: pointer;
    font-size: var(--font-xsm);
    font-weight: var(--font-weightMax);
    text-transform:uppercase;
  }
  
  .detail:hover{
    border-bottom: 1.5px solid var(--dark-red);
    transition: .1s border ease;
  }


  @media only screen and (min-width: 768px){
    .product {
        width: 25%;
      }
  }

  @media only screen and (min-width: 768px) and (max-width:1024px){
    .product {
        width: 50%;
      }
  }