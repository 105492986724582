.mid-section {
    padding: 0 12%;
    color: var(--top_bar_color);
}

.mid-section-header {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;
}

.mid-section-header p {
    font-size: var(--font-lg);
    color: var(--black);
    padding: .5rem 0;
}

.mid-section-header select {
    width: calc(100vw - 85vw);
    padding: .5rem;
    border: 1px solid var(--primary);
    box-shadow: inset 0 0 1rem 0 var(--light);
    color: var(--primary);
    border-radius: 2px;
}

.items-div {
    display: flex;
    flex-direction: column;
}


.pagination-btn{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.pagination-btn button{
    width: 80px;
    padding: .8rem;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    font-weight: var(--font-weightMid);
    margin: 0 2px;
    margin-bottom: 2rem;
  }
  
  .pagination-btn button:hover{
    box-shadow: 0 0 .8rem 0 var(--primary);
    transition: .2s box-shadow;
    
  }


@media only screen and (min-width:768px) {

    .mid-section-header {
        flex-direction: row;
    }


    .items-div {
        flex-direction: row;
        flex-wrap: wrap;
    }

}