.cart-main-div {
    padding: 0 12%;
    color: var(--top_bar_color);
    padding-top: 2%;
}

.cart-title h1{
    font-size: var(--font-lg);
    color: var(--dark-red);
    text-align: center;
}

.cart-header{
    display: flex;
    padding-top: 5%;
    padding-bottom: 2%;
    padding-right: 3.5%;
    justify-content: space-between;
}

.cart-header .left{
    flex: 2;
}

.cart-header .right{
    flex: 1.5;
    display: flex;
    justify-content: space-between;
}

.cart-head{
    color: var(--dark-red);
}

.cart-title hr, .summary-div hr{
    opacity: 50%;
}

.bottom-div{
    display: flex;
}
.bottom-div .left{
    flex: 2;
    margin-bottom: 1%;
}

.cart-items-div{
    height: 35vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 2%;
}


.bottom-div .summary-div{
    flex: 1;
    padding: 2% 5%;
    border-radius: 5px;
    background-color: var(--white);
    margin-bottom: 1%;
    margin-left: 1%;
    box-shadow: inset 0 0 1rem 0 var(--light);
}

.summary-div h3{
    text-align: center;
    font-size: var(--font-md);
    color: var(--dark-red);
    padding: 1% 0;
}

.summary-div .summary-data{
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weightMax);
}

.summary-data p{
    padding: 2% 0;
}

.summary-div .row-heading{
    color: var(--top_bar_color);
}

.summary-data .g-total{
    font-size: var(--font-md);
}

.checkout-btn{
    width: 100%;
    padding: .5rem;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    border-radius: 2px;
    color: var(--white);
    cursor: pointer;
    font-weight: var(--font-weightMid);
    margin: 10% 0;
  }
  
.checkout-btn:hover{
    box-shadow: 0 0 .8rem 0 var(--primary);
    transition: .2s box-shadow;
    
  }

  @media only screen and (max-width:768px){
    .bottom-div{
        flex-direction: column;
    }
    

  }
